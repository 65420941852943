<template>
  <v-card id="targeting" outlined>
    <v-card-text>
      <div class="d-flex align-center">
        <div class="d-flex">
          <v-btn
            v-if="this.displayRegularTargets"
            depressed
            color="primary"
            @click="openCreate"
            class="mr-2"
            >Create Audience
          </v-btn>
          <v-btn
            v-if="!this.displayRegularTargets"
            color="primary"
            to="/tradedesk/add-new-pretarget"
            class="mr-2"
            depressed
            >Add Google Pretarget</v-btn
          >
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
            class="mr-2"
          ></v-text-field>
          <v-btn
            outlined
            dense
            class="mr-2"
            color="primary"
            v-if="!displayRegularTargets"
            @click="toggleTargets"
            ><v-icon left>mdi-cog</v-icon> Show Regular Targets</v-btn
          >
          <v-btn
            outlined
            dense
            color="primary"
            @click="toggleTargets"
            v-if="superAdmin && !this.appCampaign"
            class="mr-2"
            ><v-icon left>mdi-google</v-icon>Google Pretargets</v-btn
          >
          <div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="refreshTargets()"
                  small
                  depressed
                  fab
                >
                  <v-icon> mdi-refresh </v-icon>
                </v-btn>
              </template>
              <span>Refresh Targets</span>
            </v-tooltip>
          </div>
        </div>

        <!-- <v-col cols="3" v-if="!this.displayRegularTargets">
          <span class="float-right">
            <v-btn
              small
              depressed
              outlined
              color="#8cc73e"
              class="custom-transform-class"
              to="/tradedesk/add-new-pretarget"
              >Add Google Pretarget</v-btn
            >
          </span>
        </v-col> -->
      </div>
    </v-card-text>
    <v-data-table
      :headers="regular_targeting_headers"
      :items="dsp_targets"
      :search="search"
      hide-default-footer
      disable-pagination
      v-if="displayRegularTargets"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.name="{ item }">
        <router-link :to="{ name: '', params: { id: item.id } }">
          <h5>{{ item.name }}</h5>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.campaigns="{ item }">
        <div class="campaigns">
          <span v-for="(campaign, i) in item.campaigns" :key="i">
            <span class="mr-1"> {{ campaign.name }}, </span>
          </span>
        </div>
        <!-- <span v-for="(campaign, i) in item.campaigns" :key="i">
          <span class=" campaign_names mr-1"> {{ campaign.name }}, </span>
        </span> -->
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.domain_targetting="{ item }">
        <div>
          {{ item.domain_targetting }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.countries="{ item }">
        <div class="fit">
          <ul v-for="(country, i) in item.countries" :key="i">
            <li>{{ country }}</li>
          </ul>
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <!-- <template v-slot:item.devicetype="{ item }">
        <ul v-for="(device, i) in item.devicetype" :key="i">
          <li>{{ device }}</li>
        </ul>
      </template> -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.list_of_domains="{ item }">
        <div class="fit">
          {{ item.list_of_domains.toString() }}
        </div>
      </template>

      <!-- eslint-disable-next-line -->
      <!-- <template v-slot:item.os="{ item }">
        <div>
          {{ item.os.toString() }}
        </div>
      </template> -->

      <!-- <v-card-actions class="pa-4">
      <v-spacer></v-spacer>
      <pagination />
    </v-card-actions> -->

      <!-- eslint-disable-next-line -->
      <template v-slot:item.frequency="{ item }">
        <v-btn
          small
          class="mr-5"
          color="blue"
          icon
          @click="updateTargetingRule(item.id)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>

        <v-btn small color="red" icon @click="deleteTarget(item.id)">
          <v-icon small>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-data-table
      :headers="google_pretargeting_headers"
      :items="google_pretargets"
      :search="search"
      hide-default-footer
      disable-pagination
      v-else
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.displayName="{ item }">
        <router-link :to="{ name: 'pretarget', params: { id: item.id } }">
          <h5 class="pointer">{{ item.displayName }}</h5>
        </router-link>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.created_at="{ item }">
        {{ convertDate(item.created_at) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.updated_at="{ item }">
        {{ convertDate(item.updated_at) }}
      </template>
    </v-data-table>
    <v-divider></v-divider>
    <!-- <v-card-actions
      class="d-flex justify-space-between align-center pa-4"
      v-if="dsp_targets.length && displayRegularTargets"
    >
      <div class="caption mr-3">
        Showing page {{ dsp_targets.current_page }} of
        {{ dsp_targets.last_page }}
      </div>

      <v-pagination
        v-model="page"
        :length="dsp_targets.last_page"
        :total-visible="dsp_targets.per_page"
        color="primary"
        class="custom"
        @input="pageChange"
      ></v-pagination>
    </v-card-actions> -->
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    filters: null,
    displayRegularTargets: true,
    appCampaign: false,
  }),
  props: {
    regular_targeting_headers: Array,
    google_pretargeting_headers: Array,
    dsp_targets: Array,
    google_pretargets: Array,
  },
  mounted() {
    if (
      this.$route.name === "opera-audiences" ||
      this.$route.name === "truecaller-audiences" ||
      this.$route.name === "transsion-audiences" ||
      this.$route.name === "phoenix-audiences" ||
      this.$route.name === "ctv-audiences" ||
      this.$route.name === "gaming-audiences"
    ) {
      this.appCampaign = true;
    }
  },
  methods: {
    openCreate() {
      if (this.appCampaign) {
        let app = this.$route.name.substring(0, this.$route.name.indexOf("-"));
        this.$router.push(`/${app}/add-audience`);
      } else {
        this.$router.push("/tradedesk/add-audience");
      }
    },
    updateTargetingRule(targetId) {
      this.$router.push({
        name: "edit-targeting-rules",
        params: {
          id: targetId,
        },
      });
    },
    deleteTarget(targetId) {
      this.$emit("getTargetId", targetId);
    },
    toggleTargets() {
      this.displayRegularTargets = !this.displayRegularTargets;
    },
    convertDate(date) {
      let full_date = new Date(date);
      return (
        full_date.getFullYear() +
        "/" +
        (full_date.getMonth() + 1) +
        "/" +
        full_date.getDate()
      );
    },

    refreshTargets() {
      this.$emit("refreshGoogleTargets");
    },
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    superAdmin() {
      return this.$store.getters.superAdmin;
    },
  },
};
</script>

<style scoped>
#targeting {
  margin: 2.5rem 0;
}
.campaign_names {
  word-wrap: break-word;
}
.fit {
  max-width: 500px;
  overflow-x: auto;
}
</style>
