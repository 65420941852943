<template>
  <targeting-table
    :regular_targeting_headers="regular_targeting_headers"
    :dsp_targets="dsp_targets"
    :google_pretargets="google_pretargets"
    :google_pretargeting_headers="google_pretargeting_headers"
    @getTargetId="targetDelete($event)"
    @refreshGoogleTargets="refreshGoogleTargets($event)"
  />
</template>

<script>
import TargetingTable from "../../../components/Tables/TargetingTable.vue";

export default {
  data: () => ({
    target: null,
    regular_targeting_headers: [
      {
        text: "Target Name",
        align: "start",
        sortable: false,
        value: "name",
        width: "200px",
      },

      { text: "Domain Type", width: "200px", value: "domain_targetting" },
      { text: "Domains", width: "200px", value: "list_of_domains" },
      { text: "Campaigns", width: "200px", value: "campaigns" },
      { text: "Country", width: "200px", value: "country" },
      // { text: "Device Type", width: "200px", value: "device_type"},
      // { text: "OS", width: "200px", value: "os"},
      {
        text: "Options",
        value: "frequency",
        width: "130px",
        align: "end",
      },
    ],
    google_pretargeting_headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "displayName",
        width: "300px",
      },
      { text: "Billing ID", value: "billingId", width: "170px" },
      { text: "Status", value: "state", width: "250px" },
      { text: "Maximum QPS", value: "maximumQps", width: "170px" },
      { text: "Created At", value: "created_at", width: "130px" },
      { text: "Updated At", value: "updated_at", width: "130px" },
    ],
  }),
  computed: {
    dsp_targets() {
      return this.$store.state.dsp.dsp_targets;
    },
    google_pretargets() {
      return this.$store.state.dsp.pretargeting_configs;
    },
  },
  async mounted() {
    await this.fetchtargets();
    
  },
  components: {
    TargetingTable,
  },

  methods: {
    async targetDelete(targetId) {
      this.target = targetId;
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/deleteTargetRule", this.target);
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Target Rule deleted successfully!",
          group: "success",
        });
        await this.fetchtargets();
      } catch (error) {
        this.error = error;
      }
    },
    async fetchtargets() {
      if (
        this.$route.name == "opera-audiences" ||
        this.$route.name == "phoenix-audiences" ||
        this.$route.name == "truecaller-audiences" ||
        this.$route.name == "transsion-audiences" ||
        this.$route.name == "ctv-audiences" ||
        this.$route.name == "gaming-audiences"
      ) {
        let app = this.$route.name.substring(0, this.$route.name.indexOf("-"));
        //Campaignsx
        try {
          this.$store.commit("makingApiRequest", true);
          await this.$store.dispatch("dsp/getAllAppCampaignTargets", app);
          this.$store.commit("makingApiRequest", false);
        } catch (error) {
          this.error = error;
          this.$store.commit("makingApiRequest", false);
        }
      } else {
        try {
          this.$store.commit("makingApiRequest", true);
          await this.$store.dispatch("dsp/getAllTargets");
          this.$store.commit("makingApiRequest", false);
        } catch (error) {
          this.error = error;
          this.$store.commit("makingApiRequest", false);
        }

        try {
          this.$store.commit("makingApiRequest", true);
          await this.$store.dispatch("dsp/getPretargetingConfigs");
          this.$store.commit("makingApiRequest", false);
        } catch (error) {
          this.error = error;
          this.$store.commit("makingApiRequest", false);
        }
      }
    },
    async refreshGoogleTargets() {
      try {
        this.$store.commit("makingApiRequest", true);
        await this.$store.dispatch("dsp/refreshGoogleTargets");
        this.$store.commit("makingApiRequest", false);
        this.$notify({
          title: "Targets updated successfully!",
          group: "success",
        });
      } catch (error) {
        this.error = error;
        this.$store.commit("makingApiRequest", false);
      }
    },
  },
};
</script>
